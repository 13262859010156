"use strict";
/**
 * This file contain functions that are common to every / most pages.
 *
 * NOTE: You shouldn't add new things here. If you have a stray method that needs to be shared, consider creating your
 * own file in the utils/ directory. This one is overloaded with garbage and expanding it only makes it worse.
 */

// This is replaced by the build to tell us where to find the backend services
import "./import-jquery";
import "jquery-ui-dist/jquery-ui";

import "./utils/telemetry/telemetry";
import "react-datepicker/dist/react-datepicker.css";
import "./css/main.scss";
import "datatables.net-bs4/css/dataTables.bootstrap4.min.css";
import React from "react";
import ReactDOM from "react-dom";
import ReactDOMServer from "react-dom/server";
import * as AjaxWrapper from "./utils/ajax_wrapper";
import * as FailHandlers from "./utils/fail_handlers";
import CommonSecurity from "../server/common/generic/common_security";
import * as CommonUtils from "../server/common/generic/common_utils";
import { CommonAggregateError, ERROR_CATEGORY } from "../server/common/generic/common_aggregate_error";
import * as CommonURLs from "../server/common/generic/common_urls";
import * as CommonUsers from "../server/common/editables/common_users";
import * as CommonRiskUtils from "../server/common/misc/common_risk_utils";
import _CommonLog, { Log, LOG_GROUP } from "../server/common/logger/common_log";
import Cookies from "js-cookie";
import BrowserDetector from "./helpers/browser_detector";
import "vis-timeline/dist/vis-timeline-graph2d.min.css";
import InactivityTimer from "./users/inactivityTimer/inactivity_timer";
import * as CommonEnsure from "../server/common/generic/common_ensure";
import "setimmediate";
import * as Pendo from "./utils/analytics/pendo";
import loadingImg from "./images/loading.gif";
import moment from "moment-timezone";
import { EXPERIMENTS } from "./helpers/constants/constants";
import { Telemetry } from "./utils/telemetry/telemetry";
import TypeaheadObjectCache from "./utils/cache/typeahead_object_cache";
import FullObjectCache from "./utils/cache/full_object_cache";
import BaseObjectCache from "./utils/cache/base_object_cache";
import { SESSION_STORAGE } from "./users/user_login";
// These two must be loaded using require, and I'm not sure why.
import "jquery-ui/themes/base/resizable.css";
import "jquery-ui/ui/widgets/resizable";
import "jquery-datatables-checkboxes/css/dataTables.checkboxes.css";

import "bootstrap-validator";
import "bootstrap";

// For telerik
import "@progress/kendo-theme-bootstrap/dist/all.css";
import { clearSentryUserInformation, setSentryUserFromCookies } from "./utils/sentry_utils";

import DOMPurify from "dompurify";

const Logger = Log.group(LOG_GROUP.Framework, "UIUtils");

AjaxWrapper.init();

const telemetry = Telemetry.instance();

let criticalErrors = [];
let reportedCriticalErrors = new Set();

/**
 * @typedef IErrorModalOptions
 * @property {Error} error
 * @property {boolean} [hideBody]
 * @property {boolean} [allowHide]
 * @property  {boolean} [preformatted]
 */

/**
 * @type {IErrorModalOptions}
 */
const DEFAULT_ERROR_MODAL_OPTIONS = {
  allowHide: true,
  hideBody: false,
  preformatted: true,
};

function handleUnhandledError(event) {
  event.preventDefault();
  if (!event.qbdVisionHandled) {
    const error = standardizeErrorObject(event);

    if (process.env.DEPLOY_ENV !== "local") {
      telemetry.traceError(error, event);
    } else {
      displayCriticalError(error, event);
    }
    event.qbdVisionHandled = true;
  }
}

/**
 * Captures any JavaScript errors
 */
const tagsToListenForErrors = ["script", "img", "style", "link"];
const selectorsToListenForErrors = [...tagsToListenForErrors, window, document];

for (let selector of selectorsToListenForErrors) {
  $(selector).on("error", handleUnhandledError);
}

const observer = new MutationObserver(mutations => {
  for (let mutation of mutations) {
    let newNodes = mutation.addedNodes;

    if (newNodes) {
      for (let node of newNodes) {
        if (node.tagName && tagsToListenForErrors.includes(node.tagName.toLowerCase())) {
          $(node).on("error", handleUnhandledError);
        }
      }
    }
  }
});

observer.observe($("html")[0], {subtree: true, childList: true});

BaseObjectCache.isStorageLoaded = false;

TypeaheadObjectCache.invalidateOptionsOnNewPageLoadAsync().then(() => {
  Logger.debug("TypeaheadObjectCache :: Cache cleaned");
  FullObjectCache.invalidateOptionsOnNewPageLoadAsync().then(() => {
    BaseObjectCache.isStorageLoaded = true;
    Logger.debug("FullObjectCache :: Cache cleaned");
  });
});

// load DOMPurify
window.DOMPurify = DOMPurify;

// A few helpful global requires
window.$ = $;

/**
 * Stores the original DataTables creator function so we can wrap it
 * to add telemetry
 * @type {function(*=): *}
 */


import DataTables from "datatables.net";
import DataTablesButtons from "datatables.net-buttons";
import DataTablesSelect from "datatables.net-select";
import DataTablesBS4 from "datatables.net-bs4";
import DataTablesScroller from "datatables.net-scroller";
import DataTablesCheckboxes from "jquery-datatables-checkboxes";

DataTables(window, $);
DataTablesButtons(window, $);
DataTablesSelect(window, $);
DataTablesBS4(window, $);
DataTablesScroller(window, $);
DataTablesCheckboxes(window, $);

const originalDataTableFunction = $.fn.DataTable;

/**
 * This will make DataTables invoke the error handler function specified below
 * instead of showing an alert in case of error.
 * @type {string}
 */
$.fn.dataTable.ext.errMode = "none";

/**
 * Wraps the DataTables API so that we can properly collect telemetry data on it.
 * @param options
 * @returns {*}
 * @constructor
 */
$.fn.DataTable = function(options) {
  const boundDataTableFunction = originalDataTableFunction.bind(this);
  // adds an error handler for DataTables errors in the
  const $this = $(this);
  $this.on("error.dt", (e, settings, techNote, message) => {
    Logger.error("DataTables Error:", message, Log.error(e));
    telemetry.traceError(new Error(message), {error: e, settings, techNote, message});
  });

  try {
    // uncomment this when debugging
    // Logger.warn(">> Initializing data table: ", $this, options);
    return boundDataTableFunction(options);
  } catch (dataTableError) {
    Logger.error("DataTables Error:", Log.error(dataTableError));
    telemetry.traceError(dataTableError);
    return null;
  }
};

/**
 * All properties that are available to $.fn.dataTable should also be
 * available on $.fn.DataTable
 * (copied from DataTables source)
 */
$.each(originalDataTableFunction, function(prop, val) {
  $.fn.DataTable[prop] = val;
});

/**
 * This is kind of stupid, but was a way to make sure intermittent third party libraries
 * (DataTables, I'm talking to you!)
 * errors will be reported by telemetry, but won't be shown to the user.
 * @param errorDetails
 * @returns {*}
 */
function shouldHideErrorFromEndUser(errorDetails) {
  return errorDetails && (errorDetails.includes("Cannot read property 'clientWidth' of null") || errorDetails.includes("Cannot read properties of null (reading 'clientWidth')"));
}

/**
 * The cookie we set so that other tabs know why we are logged out.
 * @type {string}
 */
export const LOGOUT_REASON = "LOGOUT_REASON";

export const PRIMARY_ALERT_DIV = "#alertDiv";

// Set sensible Cookie storage defaults, such as expiring in 12 hours (half a day) and to only transmit over HTTPs/with our site.
Cookies.defaults.expires = 0.5;
Cookies.defaults.secure = true;
Cookies.defaults.sameSite = "strict";

/**
 * Use this to sanitize popover data. As of Bootstrap 3.4.1, bootstrap will automatically try to remove any tags that
 * it deems as unsafe. Unfortunately it removes most of your data because it's pretty dumb. We use React to create
 * all of the HTML so we shouldn't need to do anything here.
 *
 * Read more here: https://getbootstrap.com/docs/3.4/javascript/#js-sanitizer
 *
 * @param content The content to sanitize / purify
 * @return {*} The exact same content.
 */
export function sanitizePopoverData(content) {
  return content;
}

// Display the appropriate editor screen, if the container exists
$(document).ready(function() {
  /*
  When you have a popup component (ex.widgets/password_requirements_panel)
  you must call popover function for it to show in top of the UI likewise
  componentDidMount() { $("[data-toggle='popover']").popover({sanitizeFn: UIUtils.sanitizePopoverData});}
   */
  // Initialize Bootstrap APIs and replace small images with bigger ones
  $("[data-toggle='popover']").popover({sanitizeFn: sanitizePopoverData});
  $("[data-toggle='validator']").validator("update");

  // Scroll a little extra on validation failure so it scrolls past the top fixed banner on the editor.
  $.fn.validator.Constructor.FOCUS_OFFSET = 120;

  // So that when you click outside of a popup, it goes away: https://stackoverflow.com/questions/11703093/how-to-dismiss-a-twitter-bootstrap-popover-by-clicking-outside
  $(document).on("click", function(e) {
    $("[data-toggle='popover'],[data-original-title]").each(function() {
      //the 'is' for buttons that trigger popups
      //the 'has' for icons within a button that triggers a popup
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $(".popover").has(e.target).length === 0) {
        (($(this).popover("hide").data("bs.popover") || {}).inState || {}).click = false; // fix for BS 3.3.6
      }
    });
  });

  $(document).on("show.bs.modal", ".modal", function() {
    let zIndex = 2000 + (10 * $(".modal:visible").length);
    $(this).css("z-index", zIndex);
    setTimeout(function() {
      $(".modal-backdrop").not(".modal-stack").css("z-index", zIndex - 1).addClass("modal-stack");
    }, 0);
  });

  try {
    Pendo.initialize();
  } catch (err) {
    console.error("Failure initializing PENDO", err);
  }
});



export function render(containerId, jsxElement, callback) {
  if ($("#" + containerId).length > 0) {
    ReactDOM.render(jsxElement, document.getElementById(containerId), callback);
  }
}

/**
 * This method secures the string from XSS attacks by escaping HTML codes. So for example, the string "Hello &lt;123&gt;"
 * will be returned as "Hello &amp;lt;123&amp;gt;".
 *
 * If looking at this comment directly in the code, the string "Hello <123>" will be secured as "Hello &lt;123&gt;".
 *
 * @param {string} someString - The string to escape.
 * @return {string} The string that cannot execute a script if printed directly to the DOM.
 */
export function secureString(someString) {
  return ReactDOMServer.renderToStaticMarkup(someString);
}

/**
 * This method secures all of the strings in an array from XSS attacks by escaping HTML codes. It calls secureString().
 *
 * @param {String} someArray - The array of strings to escape.
 * @return {[string]} The array of strings that cannot execute a script if printed directly to the DOM.
 */
export function secureArray(someArray) {
  let returnVal = [];
  for (const someString of someArray) {
    returnVal.push(secureString(someString));
  }
  return returnVal;
}

// Add the loading spinner & functions
let hideLoadingOnAjaxStop = true;
$(document.body).append("<div id='loadingDiv' class='loading-div'><img height='128' width='128' id='loadingImg'/><div id='loadingText'></div></div>");
document.getElementById("loadingImg").src = loadingImg;
// This is for dimming the page
$(document.body).append("<div id='dimWrapper' class='loading-dim-wrapper'>&nbsp;</div>");
$(document).ajaxStart(function() {
  showLoadingImage();
}).ajaxStop(function() {
  if (hideLoadingOnAjaxStop) {
    hideLoadingImage();
  }
});

/*This is for removing the beforeunload event handler every time the window unloads
so that unsaved data changes warnings are suppressed and do not show any more.
https://www.w3schools.com/jsref/event_onunload.asp
See https://cherrycircle.atlassian.net/browse/QI-1985*/
$(window).on("unload", () => {
  clearPreventNavigationForBrowser();
});

export function clearPreventNavigationForBrowser() {
  $(window).off("beforeunload");
}

export function preventNavigationForBrowser(callback = onWindowBeforeUnload) {
  $(window).on("beforeunload", callback);
}

/**
 * This function can be used in case of a lengthy process. It will keep the loading panel visible
 * if value is false till it's intentionally set to true.
 * @param value boolean value to either keep the loading image visible or not
 */
export function setHideLoadingOnAjaxStop(value) {
  hideLoadingOnAjaxStop = value;
}

export function getHideLoadingOnAjaxStop() {
  return !!hideLoadingOnAjaxStop;
}

let loading = $("#loadingDiv").hide();
let dimWrapper = $("#dimWrapper").hide();
let isPageShowing = true;
let loadingTimeout = null;
let loadingText = null;

/*Event handler for showing the native browser alert for leaving a page when unsaved changes exit.
The handler is hooked up with the beforeOnLoad window event on the BaseEditor and BaseLinksAttachmentsAttribute classes.*/
export function onWindowBeforeUnload() {
  return navigationWarning;
}

export const navigationWarning = "Are you sure you want to leave the page? Data you have entered may not be saved.";

let isLoadingDisabled = false;

/**
 * Use this to set whether the loading at the page level should be disabled or not.
 * @param isLoadingDisabledParam {boolean} true if the loading screen should not load, false otherwise.
 */
export function setLoadingDisabled(isLoadingDisabledParam) {
  isLoadingDisabled = isLoadingDisabledParam;
  Logger.debug(() => "Changing isLoadingDisabled to " + isLoadingDisabled);
  if (isLoadingDisabled) {
    this.hideLoadingImage();
  }
}

export function getLoadingDisabled() {
  return isLoadingDisabled;
}

const DIM_WRAPPER_OPACITY = 0.8;

/**
 * Show the loader / spinner to let the user know we're processing data. This method can be called multiple times with
 * different text.
 *
 * @param optionalText Text to let the user know processing is still happening
 */
export function showLoadingImage(optionalText) {
  Logger.debug(() => "Showing. Is loading disabled? " + isLoadingDisabled + " isPageShowing? " + isPageShowing + " LoadingText: " + optionalText, Log.object(dimWrapper[0]), Log.object(loading[0]));
  if (!isLoadingDisabled) {
    loading.show();
    if (isPageShowing) {
      if (loadingTimeout) {
        window.clearTimeout(loadingTimeout);
        loadingTimeout = null;
      }
      dimWrapper.stop(true, false).animate({
        opacity: DIM_WRAPPER_OPACITY,
      }, 500);
    } else {
      dimWrapper.css({opacity: DIM_WRAPPER_OPACITY});
    }
    loadingText = optionalText || "";
    if (loadingText) {
      $("#loadingText").text(loadingText);
    }
    dimWrapper.show();
    isPageShowing = false;
  }
}

export function hideLoadingImage() {
  Logger.debug(() => "Hiding. Is loading disabled? " + isLoadingDisabled + " isPageShowing? " + isPageShowing + " LoadingText: " + loadingText, Log.object(dimWrapper[0]), Log.object(loading[0]));
  isPageShowing = true;
  loadingText = null;
  $("#loadingText").text("");
  loading.hide();
  dimWrapper.stop(true, false).animate({
    opacity: 0.0,
  }, 500);

  // Stop the previous loading timeout, if this is being called twice.
  if (loadingTimeout) {
    Logger.debug("Clearing timeout to load image...");
    window.clearTimeout(loadingTimeout);
  }

  loadingTimeout = setTimeout(function() {
    dimWrapper.hide();
    // Popovers are being re-initialized below as there are cases where Datatables javascript initialization
    // takes too much time and the actual cells take longer to be rendered in the DOM.
    // An example is the risk tables report. In those cases $("[data-toggle='popover']") cannot yet find the elements
    // in the DOM that have tooltips and the tooltips do not work
    $("[data-toggle='popover']").popover({sanitizeFn: sanitizePopoverData});
  }, 500);
}

/**
 * Use this for very small wait times that most of the time we expect it to not happen at all.
 *
 * @param eventTarget {element} The event.target element before calling `UIUtils.ignoreHandler(event)` on it.
 */
export async function showLoadingCursor(eventTarget) {
  $("body").addClass("body-loading");

  // This is required or else the cursor won't appear until the user moves the mouse. See https://stackoverflow.com/q/36597412/491553
  if (eventTarget) {
    $(eventTarget).css("cursor", "progress");
  }
  await new Promise(resolve => setTimeout(resolve, 500)); // wait for the cursor.
}

/**
 * Use this to turn the cursor back to whatever it was before calling showLoadingCursor().
 *
 * @param eventTarget {element|string} The event.target element before calling `UIUtils.ignoreHandler(event)` on it.
 */
export function hideLoadingCursor(eventTarget) {
  $("body").removeClass("body-loading");
  if (eventTarget) {
    $(eventTarget).css("cursor", "");
  }
}

const SIGN_UP_EMAIL = "signup-email";

export function getSignUpEmail() {
  return sessionStorage.getItem(SIGN_UP_EMAIL);
}

export function setSignUpEmail(email) {
  return sessionStorage.setItem(SIGN_UP_EMAIL, email);
}

/**
 * This function records in the database the login activity of a user signing in the application.
 * It is invoked right after a user authenticates against the Cognito pool or completes a password challenge to change its password.
 * @param cognitoUser This is the return value of the Auth.signIn API calls.
 * @param cognitoUser.responseJSON This is the return value data in JSON/Object format
 * @param attributes This is the return value of the call to Cognito User getUserAttributes API. It contains the Cognito user attributes.
 * @param [passwordChallengeCompleted] A flag to indicate if the user completed the password challenge or just authenticated
 * before calling the recordSuccessfulLogin function.
 */
export function recordSuccessfulLogin(cognitoUser, attributes, passwordChallengeCompleted) {
  Logger.verbose(() => "Successful login. Attributes = " + JSON.stringify(attributes));
  const {encodedAccessToken, signingPin, signInUserSession: session} = cognitoUser;

  Cookies.set("ACCESS_TOKEN", encodedAccessToken || session?.accessToken.jwtToken);

  // Update the user activity (and verify the company is set up).
  AjaxWrapper.secureAjaxPUT("users/addOrEdit?onLogin=true", {signingPin}, false, (result) => {
    Logger.warn(() => "Caught error: " + stringify(result));
    if (result.responseJSON && result.responseJSON.code === USER_STATUS.CREATING) {
      // The user has just signed up and the DB is not yet ready.
      showInfo(result.responseJSON.message);
      hideLoadingImage();
    } else {
      FailHandlers.defaultFailFunction(result);
    }
  }).done((dbUser) => {
    Cookies.set("USER_NAME", attributes?.["cognito:username"] || attributes?.username || cognitoUser?.username);
    Cookies.set("USER_FULL_NAME", attributes?.name);
    Cookies.set("COGNITO_UUID", session?.idToken?.payload?.sub || attributes?.sub);
    Cookies.set("EMAIL", attributes?.email.toLowerCase());
    Cookies.remove(LOGOUT_REASON); // This cannot be removed on logout, but we don't want it infecting a future login.
    Cookies.set("COOKIES_ACCEPTED", dbUser.cookiesLevel === CommonUtils.LATEST_COOKIES_VERSION);
    Cookies.set("TERMS_ACCEPTED", dbUser.termsLevel === CommonUtils.LATEST_TERMS_VERSION);
    Cookies.set("EXPERIMENTS", dbUser.experiments ? JSON.parse(dbUser.experiments) : {});
    Cookies.set("CANNED_REPORT_STATEMENT", dbUser.cannedReportsStatement);
    Cookies.set("PROCESS_FLOW_MAP_STATEMENT", dbUser.processFlowMapReportsStatement);
    Cookies.set("language", dbUser.language);

    setTimeout(setSentryUserFromCookies, 100);

    window.localStorage.setItem("SYSTEM_USER_ID", dbUser.systemUserId);

    if (passwordChallengeCompleted) {
      AjaxWrapper.secureAjaxPUT("users/addOrEdit?onConfirm=true", {}, false).done(() => {
        updateUserSettings(dbUser);
      });
    } else {
      updateUserSettings(dbUser);
    }
  });
}

/**
 * This function updates the client with the user's billing details, right after the user authenticates.
 * @param result This is the return value of the call to Cognito User authenticateUser or completeNewPasswordChallenge API call.
 */
function updateUserSettings(result) {
  Logger.verbose(() => "Result of activity update: " + stringify(result));


  const loginReturnTo = sessionStorage[SESSION_STORAGE.RETURN_TO];
  Logger.debug(() => "Login RETURN_TO:", loginReturnTo);
  let returnTo = getParameterByName("returnTo") || loginReturnTo;
  if (!returnTo) {
    returnTo = FRONT_END_URL + DEFAULT_RETURN_TO_URL;
  }

  Cookies.set("USER_ID", result.id);
  Cookies.set("LICENSE", result.license);
  Cookies.set("COMPANY", result.Company.name);
  Cookies.set("COMPANY_ID", result.Company.id);

  let billingState = result.Company.BillingStates[0];
  Cookies.set("COMPANY_LICENSE", billingState.license);

  try {
    Pendo.initialize(result, billingState);
  } catch (err) {
    console.error("Failure initializing PENDO", err);
  }

  if (billingState.license === "trial") {
    Cookies.set("TRIAL", billingState);
    let daysLeft = CommonUtils.getDaysFromToday(billingState.trialExpiryDate);
    if (daysLeft <= 0) {
      returnTo = FRONT_END_URL + "/billing/trialExpired.html";
      clearSessionInfoForLogout();
    }
  } else if (billingState.license === "cancelled") {
    showError("This company is no longer active. Please contact sales@qbdvision.com for more information.");
    hideLoadingImage();
    clearSessionInfoForLogout();
    return;
  }

  let permissions = result.permissions;
  Cookies.set("PERMISSIONS", permissions);
  Cookies.set("IS_TRAINING_COORDINATOR", result.isTrainingCoordinator ? "true" : "false");

  setTimeout(setSentryUserFromCookies, 100);

  window.location.href = getSecuredURL(returnTo, {
    enforceHostWithinQbDVisionDomain: true,
  });

  setSentryUserFromCookies();
}

export function isExperimentEnabled(experiment) {
  const object = Cookies.getJSON("EXPERIMENTS");
  return CommonUtils.isExperimentEnabled(object, experiment);
}

/**
 * This clears all of the browser cookies & cache that keep the user logged in. Either redirect to a non-logged in page
 * immediately after this or the user will be forced back to the login page once the InactivityTimer kicks in to look
 * to see if the user is still logged in.
 */
export function clearSessionInfoForLogout() {
  Logger.info(() => "Logging out..");
  if (window.inactivityTimer) {
    // Cancel looking for logging out in another tab. This tab is logging out.
    window.inactivityTimer.cancelLogoutTimer();
  }

  // Remove all cookies for this user.
  const cookies = Object.keys(Cookies.get());
  for (let cookie of cookies) {
    if (cookie !== "language"
      && cookie !== "EXPERIMENTS"
      && cookie !== "IDENTITY_PROVIDER"
      && cookie !== LOGOUT_REASON) {
      Cookies.remove(cookie);
    }
  }

  setTimeout(clearSentryUserInformation, 100);

  BaseObjectCache.clearStorage().then(() => {
    Logger.debug("Browser storage cleared");
  });
}

export function getAccessToken() {
  return Cookies.get("ACCESS_TOKEN");
}

export function getCognitoUUID() {
  return Cookies.get("COGNITO_UUID");
}

/**
 * Retrieves the system user id for the current environment.
 * @returns {Number}
 */
export function getSystemUserId() {
  return CommonUtils.parseInt(window.localStorage.getItem("SYSTEM_USER_ID"));
}

/**
 * Checks whether the specified user id represents the system user.
 * @param userId {number} The number user id to check against the system user id.
 * @returns {boolean}
 */
export function isSystemUserId(userId) {
  // In some places of the system, we force -1 as the system user id.
  // However, this is not correct, since the system user id may vary according to the environment.
  //
  // Now that we have Import/Export, it is possible to move data from one environment to another, and system users
  // will be translated accordingly, so we need to have a more robust way of checking whether the record is pointing to
  // the system user, as there is no guarantee it will be always -1 (and it is not in many of our environments).
  return userId === getSystemUserId() || userId === -1;
}

// Add the inactivity timer
export const inactivityTimer = <InactivityTimer ref={inactivityTimer => window.inactivityTimer = inactivityTimer} />;
if (getCognitoUUID()) {
  $(document.body).append("<div id='inactivityTimer'></div>");
  ReactDOM.render(inactivityTimer, document.getElementById("inactivityTimer"));
}

export function changeInactivityTime(secondsToWarning, secondsToLogout) {
  window.inactivityTimer.changeInactivityTime(secondsToWarning, secondsToLogout);
}

/**
 * @param {string} cognitoUUID The cognito UUID of the user to check
 * @returns {boolean} true if this the cognito UUID of the currently logged in user.
 */
export function isCurrentUser(cognitoUUID) {
  return cognitoUUID === getCognitoUUID();
}

export function getCompanyLicense() {
  return Cookies.get("COMPANY_LICENSE");
}

export function getCompany() {
  return Cookies.get("COMPANY");
}

export function getEmail() {
  return Cookies.get("EMAIL");
}

export function getUserFullName() {
  return Cookies.get("USER_FULL_NAME");
}

export function getUserName() {
  return Cookies.get("USER_NAME");
}

export function getUserId() {
  return CommonUtils.parseInt(Cookies.get("USER_ID"));
}

// Sometimes params have other than integers
// (ex. unitOperationId can be "All in tech transfer)
const PARAMETER_NAME_EXCEPTIONS = ["unitOperationId"];

/**
 * Finds the given parameter in the URL and returns the value.
 * @param name The name of the parameter we're searching for.
 * @param separator The separator between the URL and the parameter.
 * @param searchString The URl to search.
 * @param sanitizeParam Prevent DOMPurify form sanitizing the string
 * @return {string|int} The value, unless the name is either "id" or ends in "Id" (ex. "projectId") in which case it will convert it to an integer.
 * @throws An "Access Denied" error if a hacker attempts an XSS attack by changing an Id to anything but a number.
 */
export function getParameterByName(name, separator = "?", searchString = window.location.search, sanitizeParam = true) {
  let match = new RegExp(`[${separator}&]` + name + "=([^&]*)").exec(searchString);
  let param = match && decodeURIComponent(match[1].replace(/\+/g, " "));
  /* Call toString on the DOMPurify.sanitize method to return the actual string for the sanitized text
     Since the trustedAPI was moved out of experimental on chrome 77, the DOMPurify.sanitize method stops
     returning back a string. Instead it returns a trustedHTML object.
   */
  if (param) {
    param = sanitizeParam ? (DOMPurify.sanitize(param) || "").toString() : param;

    // Convert Ids to numbers instead of being strings
    if ((name === "id" || name.endsWith("Id")) &&
      !PARAMETER_NAME_EXCEPTIONS.includes(name)) {
      // If it's not a number, bail. This could be an XSS attack.
      if (!CommonUtils.isNumber(param)) {
        const error = new Error(`Access denied.`);
        error.statusCode = 403;
        // Show this error.
        FailHandlers.defaultFailFunction(error);
        // Stop whoever called us.
        throw error;
      }
      param = CommonUtils.parseInt(param);
    }
  }
  return param;
}

/**
 * Gets the URL Fragment. For example, if the current URL is:
 *
 *    https://sandbox.qbdvision.com/index.html?someVar=foo#someVar=bar
 *
 * and you ask for the fragment named "someVar" then this method will return `bar`.
 *
 * @param name The name of the fragment you're looking for.
 * @returns {string|int} The value of the URL fragment.
 */
export function getFragmentByName(name) {
  return getParameterByName(name, "#", window.location.hash);
}

export function pushHistoryURLWithNewParameter(state, name, value) {
  let url;

  let match = new RegExp("[?&]" + name + "=([^&]*)").exec(window.location.search);
  if (match) {
    url = window.location.pathname + window.location.search.replace(match[0], match[0].replace(match[1], value));
  } else {
    url = window.location.pathname + window.location.search + (window.location.search.includes("?") ? "&" : "?") + name + "=" + value;
  }
  const historyState = cleanStateForHistory(state);
  const options = {
    enforceHostWithinQbDVisionDomain: true,
    baseURL: process.env.FRONT_END_URL,
    throwOnError: true,
  };

  const historyURL = getSecuredURL(url, options);

  Logger.info("Changing location state to:", Log.symbol(historyURL), Log.object(historyState));
  try {
    window.history.pushState(historyState, "", historyURL);
  } catch (err) {
    sendErrorTelemetry(err, {param: {name, value}});
  }
}

/**
 * Updates the history of URLs in the browser with a new url and state. The addOrUpdateParams
 * are used for inserting/updating the existing url params and the removedParams are used for
 * removing the params from the existing URL.
 * @param state The state to push to the history of URLs
 * @param newParams {*} An object with keys and values to upsert with the existing URL
 * @param removedParams {*} An object with keys and boolean values to remove from the existing URL.
 *                          If the boolean value is true, it removes. If it's  false, it doesn't change it.
 */
export function pushHistoryURLWithParameterChanges(state, newParams = {}, removedParams = {}) {
  // In case someone explicitly passes null or undefined
  newParams = newParams || {};
  removedParams = removedParams || {};

  Logger.verbose("Pushing history URL. Added: ", Log.object(newParams), "Removed:", Log.object(removedParams));
  let urlSearchLiteral = window.location.search;

  for (const [name, value] of Object.entries(newParams)) {
    if (typeof value !== "undefined") {
      let match = new RegExp("[?&]" + name + "=([^&]*)").exec(urlSearchLiteral);
      if (match) {
        if (match[1] === "") {
          urlSearchLiteral = urlSearchLiteral.replace(match[0], match[0] + value);
        } else {
          urlSearchLiteral = urlSearchLiteral.replace(match[0], match[0].replace(match[1], value));
        }
      } else {
        urlSearchLiteral += (urlSearchLiteral.includes("?") ? "&" : "?") + name + "=" + value;
      }
    }
  }

  const paramsToRemove = Object.entries(removedParams)
    .filter(([ignored, value]) => !!value)
    .map(([key]) => key);

  for (const param of paramsToRemove) {
    let match = new RegExp("[?&]" + param + "=([^&]*)").exec(urlSearchLiteral);
    if (match) {
      urlSearchLiteral = urlSearchLiteral.replace(match[0], "");
    }
  }

  const url = window.location.pathname + urlSearchLiteral;
  const historyState = cleanStateForHistory(state);
  const options = {
    defaultReturnToURL: "/",
    enforceHostWithinQbDVisionDomain: true,
    baseURL: process.env.FRONT_END_URL,
    throwOnError: true,
  };
  const historyURL = getSecuredURL(url, options);

  Logger.verbose("Changing location state to:", Log.symbol(historyURL), Log.object(historyState), Log.object(url), Log.object(newParams), Log.object(removedParams));

  try {
    window.history.pushState(historyState, "", historyURL);
  } catch (err) {
    const errorDetails = {
      newParams: JSON.stringify(newParams),
      removedParams: JSON.stringify(removedParams),
    };
    Logger.error(() => "Error writing history. History State:", Log.object(historyState), "\nError: ", Log.error(err));
    sendErrorTelemetry(err, errorDetails);
  }
}

/**
 * Remove the functions from the state. Functions can't be pushed into the history.
 */
export function cleanStateForHistory(state) {
  if (!state) {
    return state;
  }

  const stateWithNoFunctions = {};
  for (const key of Object.keys(state)) {
    if (typeof key !== "function" && typeof state[key] !== "function" && !key.startsWith("_")) {
      stateWithNoFunctions[key] = state[key];
    }
  }
  return stateWithNoFunctions;
}

// Show a warning message for the user.
export function showWarning(message, details, alertDivParam, includesHTML = false) {
  this.showError(message, details, alertDivParam, includesHTML, "alert-warning");
}

/**
 * Show an error message for the user.
 * @param message {string|object} The message to show.
 * @param [details] {string|object} The details to put under the message.
 * @param [alertDivParam] {object} The optional div element to render the error inside of.
 * @param [includesHTML] {boolean}True if there is html in the error (not recommended for security reasons).
 * @param [clazz] {string} The class to apply to the error.
 */
export function showError(message, details, alertDivParam, includesHTML = false, clazz = "alert-danger") {
  /**
   * @type {Error|*}
   */
  let errorObject = null;
  let alertDivSelector = ensureAlertDiv(alertDivParam);
  let detailsText;
  let errorInfo;
  let errorIDs = [];
  includesHTML = (includesHTML || (message && message.error && message.error.includesHTML));

  if (React.isValidElement(message)) {
    message = ReactDOMServer.renderToStaticMarkup(message);
    includesHTML = true;
  } else if (React.isValidElement(message.reactComponent)) {
    message = ReactDOMServer.renderToStaticMarkup(message.reactComponent);
    includesHTML = true;
  } else if (message.errorText) {
    errorInfo = message;
    details = message.errorDetails;
    message = message.errorText;
  }

  let messages = [];

  showAlertDiv(alertDivSelector, clazz);
  const alertDiv = $(alertDivSelector);
  const alertDivExists = !!(alertDiv && alertDiv[0]);

  if (alertDivExists && includesHTML) {
    alertDiv.html(message);
    messages.push(message);
  } else {
    if (details && typeof details === "object") {
      detailsText = CommonUtils.stringify(details);
    } else {
      detailsText = details;
    }

    if (typeof message === "string") {
      messages = message.split("\n");
      messages = messages.map(currentMessage => {
        let result;

        // tries to get an object if the message is a JSON string
        try {
          let item = JSON.parse(currentMessage);

          // displays better error message for routing errors (should happen only locally)
          if (item.statusCode && item.error && item.currentRoute) {
            result = `${item.statusCode} - ${item.error}: ${item.currentRoute}`;
            if (item.existingRoutes) {
              detailsText += `Existing routes: \n\t${item.existingRoutes.join("\n\t")}\n`;
            }
          } else if (item.error) {
            result = item.error;
          } else if (item.message) {
            result = item.message;
            if (item.stack && !item.isValidation) {
              detailsText += `${item.stack}\n`;
              if (item.uuid) {
                errorIDs.push(item.uuid);
              }
            }
          } else {
            result = item.toString();
          }
        } catch (err) {
          result = currentMessage;
        }
        return result;
      });

    } else if (message.error) {
      if (typeof message.error.message === "string") {
        messages = message.error.message.split("\n");
      } else {
        messages = message.error.toString().split("\n");
      }
      errorObject = new CommonAggregateError(message.error);
    } else if (typeof message.message === "string") {
      messages = message.message.split("\n");
      errorObject = new CommonAggregateError(message);
    } else {
      messages = [CommonUtils.stringify(message)];
    }
  }

  // Shows all unique error messages
  let errorTexts = [...new Set(messages)];

  if (alertDivExists) {

    alertDiv.empty();

    for (let errorText of errorTexts) {
      alertDiv.append(errorText);
      alertDiv.append("<br>");
    }

    if (detailsText || (errorTexts.some(text => text.startsWith("Unexpected")) && errorIDs?.length > 1)) {
      alertDiv.append(`
        <br /><br />Please include these details when <a href="https://support.qbdvision.com">contacting support</a>:<br />
        ${errorIDs.length > 0 ? `Error ID${errorIDs.length > 1 ? "s" : ""}: ${errorIDs.map(err => `<b>${err.uuid}</b>`).join(", ")}<br />` : ""}
          ${detailsText ? `<pre id='errorDetailsPre' class='error-details'/>` : ""}
        `);
      if (detailsText) {
        $("#errorDetailsPre").text(detailsText);
      }
    }
  }
  // Scrolls the user to the top of the page to see the error message
  if (!alertDivParam) {
    const isQuickPanel = alertDivSelector === "#quickPanelAlertDiv";
    scrollToField(alertDivSelector, isQuickPanel ? 250 : 100, isQuickPanel ? "#quickPanelInnerContainer" : null);
  }

  if (!errorObject) {
    errorObject = new Error(errorTexts.join("\n"));
    // appends the error info object as part of the error to be traced.
    Object.assign(errorObject, errorInfo);
  }

  if (!errorObject.stack) {
    Error.captureStackTrace(errorObject);
  }

  if (detailsText) {
    errorObject.detailsText = detailsText;
  }

  const $gracefulErrorMessage = $(".graceful-error-message");
  const isGracefulErrorMessageVisible = $gracefulErrorMessage[0] && !$gracefulErrorMessage.is(":visible");

  if (!message.alreadyLogged || !errorObject || !errorObject.alreadyLogged) {
    Logger.error(() => `Received error (s): ${errorObject.message}`, Log.error(errorObject), Log.id(alertDivExists, "Alert Div Exists?"), Log.id(isGracefulErrorMessageVisible, "Is Graceful Error Message Visible?"));

    if (message && typeof message === "object") {
      message.alreadyLogged = true;
    }

    if (errorObject && typeof errorObject === "object") {
      errorObject.alreadyLogged = true;
    }
  }

  const event = {message, details, requestParams: message && message.requestParams};

  // If we have an alert div, all is fine, we send telemetry,
  // otherwise, we display the critical error modal
  // (that method will also send telemetry, so we don't do it here)
  if (alertDivExists) {
    telemetry.traceError(errorObject, event);
  } else {
    if (!isGracefulErrorMessageVisible && (errorObject.category || errorInfo?.category) !== ERROR_CATEGORY.LICENSE_UPGRADE_REQUIRED) {
      displayCriticalError(errorObject, event);
    }
  }
}

/**
 * Scroll to the given selector minus the offset
 * @param fieldId The ID to scroll to
 * @param offset Subtract the offset to scroll up a bit extra to give context.
 * @param containerId The container Id containing the fieldId. If no container id is specified, then
 * the body of the page is considered to be the container.
 * @param useAnimation Set by default to true to animate the scrollbar movement
 */
export function scrollToField(fieldId, offset = 100, containerId = null, useAnimation = true) {
  let container = containerId ? $(containerId) : $("html, body");
  let field = containerId ? container.find(fieldId) : $(fieldId);
  if (field && field.length > 0) {
    container.animate({
      scrollTop: containerId ? container.scrollTop() + field.offset().top - container.offset().top - offset : field.offset().top - offset,
    }, useAnimation ? 500 : 0);
  }
}

/**
 * Returns whether or not a given element or react component is visible.
 * @param element {*} The element or selector to verify if it's visible.
 * @return {boolean} A boolean indicating whether or not this element is visible.
 */
export function isVisible(element) {
  return !!(element && $(element).is(":visible"));
}

/**
 * Checks if a given object is empty
 * @param obj The object to check
 * @returns {boolean} Either object is empty or not
 */
export function isEmpty(obj) {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key))
      return false;
  }
  return true;
}

export function clearError(alertDiv = null) {
  alertDiv = ensureAlertDiv(alertDiv);
  clearErrorHelper(alertDiv);

  // Clear the primary alert too if it exists.
  if (alertDiv !== PRIMARY_ALERT_DIV && $(PRIMARY_ALERT_DIV).length > 0) {
    clearErrorHelper(PRIMARY_ALERT_DIV);
  }
}

function clearErrorHelper(alertDiv) {
  const alertClasses = $(alertDiv).attr("class");
  if (alertClasses && alertClasses.includes("alert-danger")) { // Only clear errors, not success/info messages.
    $(alertDiv).text("");
    $(alertDiv).removeClass("d-block").addClass("d-none");
    // uncomment for verbose logging (logging as error, so you know where it's cleared from)
    // Logger.error(() => "Clearing alert div");
  }
}

function showAlertWithMessage(message, alertDiv, alertClass, scrollToAlert = false) {
  alertDiv = ensureAlertDiv(alertDiv);
  let includeHTML = false;

  if (React.isValidElement(message)) {
    message = ReactDOMServer.renderToStaticMarkup(message);
    includeHTML = true;
  }
  showAlertDiv(alertDiv, alertClass);

  if (includeHTML) {
    $(alertDiv).html(message);
  } else {
    $(alertDiv).text(message);
  }

  if (scrollToAlert) {
    scrollToAlertDiv(alertDiv);
  }

  return message;
}

export function showSuccess(message, alertDiv = null, scrollToAlert = false) {
  message = showAlertWithMessage(message, alertDiv, "alert-success", scrollToAlert);
  Logger.info(() => "Showing success: ", message);
}

export function showInfo(message, alertDiv = null, scrollToAlert = false) {
  showAlertWithMessage(message, alertDiv, "alert-info", scrollToAlert);
}

function showAlertDiv(alertDivSelector, clazz) {
  const alertDiv = $(alertDivSelector);
  alertDiv.removeClass("alert-danger").removeClass("alert-info").removeClass("alert-success");
  alertDiv.addClass(clazz);
  alertDiv.removeClass("d-none").addClass("d-block");
}

function ensureAlertDiv(alertDiv) {
  if (!alertDiv) {
    const quickPanelAlertDiv = $("#quickPanelAlertDiv");
    if (quickPanelAlertDiv.length > 0 && $("#quickPanelInnerContainer").is(":visible")) {
      alertDiv = "#quickPanelAlertDiv";
    } else {
      alertDiv = PRIMARY_ALERT_DIV;
    }
  }

  return alertDiv;
}

export function scrollToAlertDiv(alertDiv = undefined) {
  scrollToField(ensureAlertDiv(alertDiv));
}

export function incrementReactComponentDidUpdateCounter(increment) {
  if (!window.callsToComponentDidUpdate) {
    window.callsToComponentDidUpdate = 1;
  } else {
    window.callsToComponentDidUpdate = window.callsToComponentDidUpdate + (increment ? increment : 1);
  }
}

/**
 * The problem is that Chrome seems to think a PDF page is 1070 pixels with 0,0 being the upper left hand corner,
 * while Firefox thinks a PDF page is 792 pixels (they're right) with 0,0 being the lower left hand corner. So
 * we specify the placement in Chrome and then convert it to Firefox.
 *
 * MS Edge doesn't support even going to the right page, never mind scrolling to the right location.
 */
export function computePdfURL(url, page, offset) {
  if (page) {
    switch (BrowserDetector.detectBrowser()) {
      case BrowserDetector.Browser.CHROME:
        url += "#page=" + page + (offset ? "&zoom=100,0," + offset : "");
        break;
      case BrowserDetector.Browser.FIREFOX:
        url += "#page=" + page;
        if (offset) {
          let firefoxOffset = Math.floor(792 - (CommonUtils.parseInt(offset) / 1070) * 792);
          url += "&zoom=100,0," + firefoxOffset;
        }
    }
  }
  return url;
}

//https://stackoverflow.com/questions/1134586/how-can-you-find-the-height-of-text-on-an-html-canvas
export function getTextSize(fontOptions, textToMeasure) {
  const {fontFamily, fontSize, fontWeight, fontStyle} = fontOptions;

  let textSpan = $("#textMeasureSpan");
  let block = $("#textMeasureBlock");
  let div = $("#textMeasureDiv");
  if (textSpan.length) {
    textSpan.text(textToMeasure).css({
      "font-family": fontFamily,
      "font-size": fontSize,
      "font-weight": fontWeight,
      "font-style": fontStyle ? fontStyle : "normal",
    });
  } else {
    textSpan = $(`<span id="textMeasureSpan">${secureString(textToMeasure)}</span>`).css({
      "font-family": fontFamily,
      "font-size": fontSize,
      "font-weight": fontWeight,
      "font-style": fontStyle ? fontStyle : "normal",
    });
    block = $("<div id='textMeasureBlock' style='display: inline-block; width: 1px; height: 0;'></div>");
    div = $("<div id='textMeasureDiv' style='position: absolute; float: left; white-space: nowrap; visibility: hidden;'></div>");
    let body = $("body");
    body.append(div);
    div.append(textSpan, block);
  }

  let result = {};
  block.css({
    verticalAlign: "baseline",
  });
  result.ascent = block.offset().top - textSpan.offset().top;
  block.css({
    verticalAlign: "bottom",
  });
  result.height = block.offset().top - textSpan.offset().top;
  result.descent = result.height - result.ascent;
  result.width = div.width() - 1; //We subtract 1 due to the block div width used to measure the text height.

  return result;
}

/**
 * Puts commas to a number
 * https://stackoverflow.com/a/17663871/491553
 */
export function addCommasToNumber(number) {
  return Number(number).toLocaleString();
}

/**
 * This provides the CSS classes for showing the skeleton shimmer
 * @param {boolean} isLoading - the component's isLoading function
 * @param {string} [additiveSkeletonClass=""] - a custom class for your skeleton shimmer needs
 * @returns {string|string}
 */
export function getClassForLoading(isLoading, additiveSkeletonClass = "") {
  return isLoading ? ` skeleton ${additiveSkeletonClass}` : "";
}

/**
 * Use this when you want to ensure that an event doesn't get passed to other event handlers (which is almost always).
 * Otherwise, the same click event will get handled by every parent div that has an onClick handler and the backend
 * will get called multiple times.
 *
 * @param event the event passed in by React
 */
export function ignoreHandler(event) {
  if (event) {
    event.preventDefault();
    event.stopPropagation();
  }
}

/**
 * Converts the date into a String that shows the date and time in the user's timezone.
 *
 * @param someDate The date which we wish display
 * @param format The format which we wish display
 * @return a string that shows the date and time in the user's timezone.
 */
export function getDateForDisplayToUser(someDate, format) {
  return moment(someDate).tz(moment.tz.guess()).format(format || LONG_DATE_FORMAT_FOR_DISPLAY);
}

/**
 * Converts the incomming object into a Date if it's a moment. If not, it leaves it alone.
 *
 * @param someObject {object} Some object, possibly a moment.
 * @return {Date} A date, if possible.
 */
export function convertMomentToDate(someObject) {
  return moment.isMoment(someObject) ? someObject.toDate() : someObject;
}

function standardizeErrorObject(error) {
  let result;

  if (typeof error === "string") {
    // Error is a string. Just collect the message.
    result = new Error(error);
  } else if (error.originalEvent) {
    // If this is a jQuery wrapped event, runs it from the original event
    result = standardizeErrorObject(error.originalEvent);
  } else if (error.stack) {
    // Error is an error object. Let's pass it on.
    result = error;
  } else if (error.error) {
    // This is probably an ErrorEvent that reports a javascript error from window.onerror.
    // Let's then standardize the error it carries over.
    result = standardizeErrorObject(error.error);
  } else {
    // This is probably an Event that reports a resource load error.
    // In this case, we have no error object and we need to extract the information ourselves.
    result = new Error();

    if (error.message) {
      result.message = error.message;
    }

    if (error.type === "error" && !!error.target) {
      const srcElement = error.target;
      const message = `Unable to load resource (${srcElement.tagName})`;
      if (!result.message) {
        result.message = message;
      }
      result.stack = `${message}:
      - URL: "${srcElement.src}"${error.path ? `
      - Element Path: ${(error.path || []).filter(pathItem => !!pathItem.tagName).reverse().map(pathItem => `${pathItem.tagName}${pathItem.id ? `#${pathItem.id}` : ""}`).join(" > ")}\n` : ""}
      `;
    } else {
      try {
        result.message = JSON.stringify(error);
      } catch (e) {
        result.message = error ? error.toString() : "Unknown error";
      }
    }
  }
  return result;
}

/**
 * Displays an error in a modal window
 * @param title {string} The modal title
 * @param detailsText {string} The details text displayed in the modal body
 * @param options {IErrorModalOptions} The options for this modal
 */
export function displayErrorModal(title, detailsText, options = DEFAULT_ERROR_MODAL_OPTIONS) {
  options = {...DEFAULT_ERROR_MODAL_OPTIONS, ...(options || {})};

  if (options && options.error && !options.error.alreadyLogged) {
    Logger.error(() => "Unexpected error:", detailsText, Log.Error(options.error));
    options.error.alreadyLogged = true;
  }

  let {allowHide, hideBody, preformatted} = options;

  if (hideBody) {
    const $bodyDiv = $("#bodyDiv");
    $bodyDiv.addClass("d-none");
  }
  let $modal = $("#criticalErrorModal");
  let detailsTag = preformatted ? "pre" : "div";
  if (!$modal[0]) {
    // this function doesn't use react intentionally, so it can display the error even if react components fail
    $modal = $(`
      <div id="criticalErrorModal" class="modal fade" tabindex="-1" role="dialog" style="z-index: +99999999">
        <div class="modal-dialog modal-lg" role="document">
          <div class="modal-content">
            <div class="modal-header danger">
              <h1 class="error-title">${title}</h1>
            </div>
            <div class="modal-body">
              <div id="modalAlertDiv">
                <${detailsTag} class="error-details">${detailsText}</${detailsTag}>
              </div>
            </div>
            <div class="modal-footer">
             <button class="btn btn-primary" id="backToHomePageButton" onclick="window.location.href = '${FRONT_END_URL + DEFAULT_RETURN_TO_URL}'">Back to Home Page</button>
             <button class="btn btn-secondary d-none" id="dismissButton" onclick="window.clearQbDVisionCriticalErrors()">Dismiss</button>
            </div>
         </div>
      </div>
    </div>
    `,
    );
    $("body").append($modal);
  }

  const $title = $modal.find(".error-title");
  $title.text(title);

  const $details = $modal.find(".error-details");
  $details.text(detailsText);

  const $dismissButton = $modal.find("#dismissButton");
  $dismissButton.toggleClass("d-none", !allowHide);

  if (!$modal.is(":visible")) {
    $modal.modal({keyboard: false, show: true, backdrop: "static"});
  }

  // Try to hide the loading screen, if it's still up.
  try {
    hideLoadingImage();
    setHideLoadingOnAjaxStop(true);
  } catch (error) {
    Logger.error("Caught this unexpected error while trying to hide the loading screen:", error);
  }
}

/**
 * Displays a critical error modal instead of White Screen of Death (WSoD)
 * @param {Error} error
 * @param detailsObject {*}
 */
export function sendErrorTelemetry(error, detailsObject) {
  Telemetry.instance().traceError(error, detailsObject);
}

/**
 * Displays a critical error modal instead of White Screen of Death (WSoD)
 * @param {Error} error
 * @param errorInfo
 */
export function displayCriticalError(error, errorInfo = {}) {
  if (error.message && error.stack &&
    error.message === "Unable to load resource (SCRIPT)" &&
    error.stack.indexOf(".js?") !== -1) {

    const url = new URL(window.location.href);
    url.searchParams.set("t", `${+new Date()}`);


    let retryNo = parseInt(url.searchParams.get("retry"));
    if (retryNo) {
      retryNo++;
    } else {
      retryNo = 1;
    }

    url.searchParams.set("retry", retryNo);


    if (retryNo <= 5) {
      window.location.href = url.href;
      return;
    }
  }

  error = standardizeErrorObject(error);

  let detailsObject = errorInfo || {};
  let errorDetails = [];

  if (reportedCriticalErrors.has(error)) {
    return;
  }

  reportedCriticalErrors.add(error);

  errorDetails.push(`Error ${criticalErrors.length + 1}`);
  const errorDescription = error.stack
    ? error.stack
    : (
      error.message || detailsObject.errorText || (
        typeof error === "object"
          ? JSON.stringify(error)
          : error.toString()
      )
    );

  errorDetails.push(errorDescription);

  if (detailsObject instanceof ErrorEvent) {
    errorDetails.push("\n--- EVENT DETAILS: ---\n");
    errorDetails.push(` - File: ${detailsObject.filename} (${detailsObject.lineno}:${detailsObject.colno})`);
    errorDetails.push(` - Message: ${detailsObject.message}`);
    errorDetails.push(` - Default Prevented: ${detailsObject.defaultPrevented}`);
    errorDetails.push(` - Bubbles: ${detailsObject.bubbles}`);
  }

  if (detailsObject.componentStack) {
    errorDetails.push(`\n -- React Component Stack: \n`);
    errorDetails.push(`${detailsObject.componentStack}`);
  }

  sendErrorTelemetry(error, detailsObject);

  if (!error.alreadyLogged) {
    Logger.error(() => "Unexpected error:", errorDetails, Log.error(error));
    error.alreadyLogged = true;
  }

  criticalErrors.push(errorDetails.join("\n"));

  const criticalErrorDetails = criticalErrors.join("\n\n-------------\n\n");

  if (shouldHideErrorFromEndUser(criticalErrorDetails)) {
    return;
  }

  let title = (detailsObject && detailsObject.title);
  if (!title) {
    if (error.statusCode === 403) {
      title = "Access Denied";
    } else if (error.statusCode === 404) {
      title = "Not found";
    } else {
      title = "Unexpected error";
    }
  }
  displayErrorModal(title, criticalErrorDetails, {error});
}

export function clearCriticalErrors() {
  criticalErrors = [];
  $("#criticalErrorModal").modal("hide");
  hideLoadingImage();
}

window.clearQbDVisionCriticalErrors = clearCriticalErrors;

export function objectToURLParameters(object) {
  if (!object) {
    return "";
  }
  return Object.entries(object).map(entry => entry.join("=")).join("&");
}

export function getLanguageUrlForDataTable(language = "en") {
  const isTranslationExperimentEnabled = isExperimentEnabled(EXPERIMENTS.Translation);
  if (!isTranslationExperimentEnabled) {
    language = "en";
  }

  // If the language is not supported, use English.
  if (!LANGUAGE_OPTIONS[language]) {
    language = "en";
  }
  return `/i18n/locales/${language}/datatable.json`;
}

// Borrowed heavily from https://stackoverflow.com/a/39165137/491553
export function findReactComponentForNode(domElement, traverseUp = 0) {
  if (!domElement) {
    throw new Error("Cannot find React component:" + domElement);
  }

  const key = Object.keys(domElement).find(key => key.startsWith("__reactInternalInstance$"));
  const domFiber = domElement[key];

  // React 16+
  const getComponentForFiber = fiber => {
    let parentFiber = fiber.return;
    while (typeof parentFiber.type === "string") {
      parentFiber = parentFiber.return;
    }
    return parentFiber;
  };
  let compFiber = getComponentForFiber(domFiber);
  for (let i = 0; i < traverseUp; i++) {
    compFiber = getComponentForFiber(compFiber);
  }
  return compFiber.stateNode;
}

/**
 * Breaks large array to small arrays of specific size
 * @param array The large array to break
 * @param numberOfRecords The number of records per new smaller array
 * @returns {Array} Array of small arrays
 */
export function spliceArray(array, numberOfRecords) {
  let arrayBlocks = [];
  let newArray = array.slice();

  while (newArray.length) {
    let block = newArray.splice(0, numberOfRecords);
    arrayBlocks.push(block);
  }
  return arrayBlocks;
}

/**
 * Invokes a callback with the specified arguments if the callback is set
 * @param callback {Function} the callback to be invoked (if it's not falsy)
 * @param args {*} The arguments for that callback
 * @return {*} The result of the callback invocation
 */
export function invokeCallback(callback, ...args) {
  if (callback && typeof callback === "function") {
    return callback(...args);
  }
}

/**
 * Invokes a callback asynchronously with the specified arguments if the callback is set
 * @param callback {Function} the callback to be invoked (if it's not falsy)
 * @param args {*} The arguments for that callback
 * @return {*} The result of the callback invocation
 */
export async function invokeCallbackAsync(callback, ...args) {
  return await Promise.resolve(invokeCallback(callback, ...args));
}

export function isPromise(promise) {
  return (promise && typeof promise.then === "function" && promise[Symbol.toStringTag] === "Promise");
}

// CommonUtils exports
export const API_URL = process.env.API_URL;
export const WS_API_URL = process.env.WS_API_URL;
export const stripAllWhitespaces = CommonUtils.stripAllWhitespaces;
export const LANGUAGE_OPTIONS = CommonUtils.LANGUAGE_OPTIONS;
export const DATE_FORMAT_FOR_DISPLAY = CommonUtils.DATE_FORMAT_FOR_DISPLAY;
export const DATE_FORMAT_FOR_TABLE_DISPLAY = CommonUtils.DATE_FORMAT_FOR_TABLE_DISPLAY;
export const DATE_FORMAT_FOR_DISPLAY_DATEPICKER = CommonUtils.DATE_FORMAT_FOR_DISPLAY_DATEPICKER;
export const LONG_DATE_FORMAT_FOR_DISPLAY = CommonUtils.LONG_DATE_FORMAT_FOR_DISPLAY;
export const LONG_DATE_FORMAT_WITH_SECONDS_FOR_DISPLAY = CommonUtils.LONG_DATE_FORMAT_WITH_SECONDS_FOR_DISPLAY;
export const LONG_DATE_FORMAT_FOR_DISPLAY_DATEPICKER = CommonUtils.LONG_DATE_FORMAT_FOR_DISPLAY_DATEPICKER;
export const DATE_FORMAT_FOR_STORAGE = CommonUtils.DATE_FORMAT_FOR_STORAGE;
export const DATE_TIME_FORMAT_FOR_STORAGE = CommonUtils.DATE_TIME_FORMAT_FOR_STORAGE;
export const DATE_TIME_FORMAT_FOR_STORAGE_WITHOUT_MILLISECONDS = CommonUtils.DATE_TIME_FORMAT_FOR_STORAGE_WITHOUT_MILLISECONDS;
export const DATE_TIME_FORMAT_FOR_DISPLAY_WITHOUT_MILLISECONDS = CommonUtils.DATE_TIME_FORMAT_FOR_DISPLAY_WITHOUT_MILLISECONDS;
export const RELEASE = CommonUtils.RELEASE;
export const RELEASE_EDITION = CommonUtils.RELEASE_EDITION;
export const RELEASE_NUMBER = CommonUtils.RELEASE_NUMBER;
export const VERSION_STATES = CommonUtils.VERSION_STATES;
export const USER_STATUS = CommonUtils.USER_STATUS;
export const MEASUREMENT_TYPES = CommonUtils.MEASUREMENT_TYPES;
export const capitalize = CommonUtils.capitalize;
export const stripSpecialChars = CommonUtils.stripSpecialChars;
export const convertToId = CommonUtils.convertToId;
export const convertCamelCaseToSpacedOutWords = CommonUtils.convertCamelCaseToSpacedOutWords;
export const startsWithCapital = CommonUtils.startsWithCapital;
export const convertPhoneNumberToCognitoFormat = CommonUtils.convertPhoneNumberToCognitoFormat;
export const convertToCamelCaseId = CommonUtils.convertToCamelCaseId;
export const generateUUID = CommonUtils.generateUUID;
export const getDaysFromToday = CommonUtils.getDaysFromToday;
export const formatBytes = CommonUtils.formatBytes;
export const getEndOfDayForDate = CommonUtils.getEndOfDayForDate;
export const getKeyForSorting = CommonUtils.getKeyForSorting;
export const getModelNameForTypeCode = CommonUtils.getModelNameForTypeCode;
export const findModelNameForTypeCode = CommonUtils.findModelNameForTypeCode;
export const getSoftwareVersion = CommonUtils.getSoftwareVersion;
export const getTypeCodeForModelName = CommonUtils.getTypeCodeForModelName;
export const findTypeCodeForModelName = CommonUtils.findTypeCodeForModelName;
export const toUniqueList = CommonUtils.toUniqueList;
export const getPluralizedModelName = CommonUtils.getPluralizedModelName;
/**
 * Returns a label fit for displaying a record for the user
 * @param typeCode {string} The type code of the model of the record
 * @param id {number} The id of the record (usually the id field in the database)
 * @param name {string} The name of the record (usually the value of the name field in database)
 * @return {string|string}
 * @deprecated Use {@link getRecordCustomLabelForDisplay}
 */
export const getRecordLabelForDisplay = CommonUtils.getRecordLabelForDisplay;
export const parseKey = CommonUtils.parseKey;
export const parseInt = CommonUtils.parseInt;
export const pluralize = CommonUtils.pluralize;
export const singularize = CommonUtils.singularize;
export const stringify = CommonUtils.stringify;
export const uncapitalize = CommonUtils.uncapitalize;
export const uncapitalizeAllText = CommonUtils.uncapitalizeAllText;
export const isNumericArray = CommonUtils.isNumericArray;
export const isNumber = CommonUtils.isNumber;
export const convertToNumber = CommonUtils.convertToNumber;
export const isInteger = CommonUtils.isInteger;
export const sortBy = CommonUtils.sortBy;
export const FRONT_END_URL = process.env.FRONT_END_URL;
export const FRONT_END_HOST = CommonUtils.FRONT_END_HOST;
export const deepClone = CommonUtils.deepClone;
export const getFileExtension = CommonUtils.getFileExtension;
export const isEnvironmentPublicToClients = CommonUtils.isEnvironmentPublicToClients;
export const getRecordCustomLabelForDisplay = CommonUtils.getRecordCustomLabelForDisplay;
export const getRecordCustomLabelForDisplayAlternate = CommonUtils.getRecordCustomLabelForDisplayAlternate;
export const getRecordCustomIdForDisplay = CommonUtils.getRecordCustomIdForDisplay;
export const getRecordCustomIdForSorting = CommonUtils.getRecordCustomIdForSorting;
export const getDeployEnvironment = CommonUtils.getDeployEnvironment;

// AjaxWrapper exports
export const getURL = AjaxWrapper.getURL;
export const secureAjaxGET = AjaxWrapper.secureAjaxGET;
export const secureAjaxPOST = AjaxWrapper.secureAjaxPOST;
export const secureAjaxPUT = AjaxWrapper.secureAjaxPUT;
export const secureAjaxDELETE = AjaxWrapper.secureAjaxDELETE;
export const defaultFailFunction = FailHandlers.defaultFailFunction;

// BrowserDetector exports
export const detectBrowser = BrowserDetector.detectBrowser;
export const Browser = BrowserDetector.Browser;

// CommonUsers exports
export const computeUsername = CommonUsers.computeUsername;

// CommonSecurity exports
export const isFileNameValidForUpload = CommonSecurity.isFileNameValidForUpload;

// CommonURLs exports
export const cleanUpURL = CommonURLs.cleanUpURL;
export const isValidURL = CommonURLs.isValidURL;
export const getSecuredURL = CommonURLs.getSecuredURL;
export const DEFAULT_RETURN_TO_URL = CommonURLs.DEFAULT_RETURN_TO_URL;

// CommonRisk exports
export const RMP_TO_RISK_ATTRIBUTES = CommonRiskUtils.RMP_TO_RISK_ATTRIBUTES;
export const RISK_MODELS = CommonRiskUtils.RISK_MODELS;

/**
 * @type {typeof Ensure}
 */
export const Ensure = CommonEnsure.Ensure;

export const CommonLog = _CommonLog;

export const extactNumberFromReactElement = (element) => {
  const value = element && Array.isArray(element) ? ReactDOMServer.renderToStaticMarkup(element).replace(/(<([^>]+)>)/ig, "") : (element ?? "");
  const valueAsNumber = parseFloat(value);
  return !Number.isNaN(valueAsNumber) ? valueAsNumber : 0;
};

export const measurePerformanceStart = (interactionName) => {
  if (!Logger.isDebug) {
    return;
  }

  performance.mark(interactionName + " start");
};

export const measurePerformanceEnd = (interactionName) => {
  if (!Logger.isDebug) {
    return;
  }

  performance.mark(interactionName + " end");

  const measure = performance.measure(
    interactionName + " duration",
    interactionName + " start",
    interactionName + " end",
  );

  Logger.debug(interactionName + " interaction took", measure.duration, "ms");
};

export const objectDeepDiffMapper = function() {
  return {
    VALUE_CREATED: "created",
    VALUE_UPDATED: "updated",
    VALUE_DELETED: "deleted",
    VALUE_UNCHANGED: "unchanged",
    map: function(obj1, obj2, ignoreFields = []) {
      ignoreFields.push("i18n", "t");

      if (this.isFunction(obj1) || this.isFunction(obj2)) {
        throw "Invalid argument. Function given, object expected.";
      }
      if (this.isValue(obj1) || this.isValue(obj2)) {
        return {
          type: this.compareValues(obj1, obj2),
          data: obj1,
          oldData: obj2
        };
      }

      let diff = {};
      for (let key in obj1) {
        if (ignoreFields.includes(key)) {
          continue;
        }

        if (this.isFunction(obj1[key])) {
          continue;
        }

        let value2 = undefined;
        if (obj2[key] !== undefined) {
          value2 = obj2[key];
        }

        diff[key] = this.map(obj1[key], value2);
      }

      for (let key in obj2) {
        if (ignoreFields.includes(key)) {
          continue;
        }

        if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
          continue;
        }

        diff[key] = this.map(undefined, obj2[key]);
      }

      return diff;

    },
    getDifferences(obj1, obj2, ignoreFields) {
      const result = this.map(obj1, obj2, ignoreFields);
      const newObject = {};
      for (let key in result) {
        if (!result[key] || !result[key].type || result[key].type === "unchanged") {
          continue;
        }

        newObject[key] = result[key];
      }

      return newObject;
    },
    compareValues: function(value1, value2) {
      if (value1 === value2) {
        return this.VALUE_UNCHANGED;
      }

      if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
        return this.VALUE_UNCHANGED;
      }

      if (value1 === undefined && value2 !== undefined) {
        return this.VALUE_CREATED;
      }

      if (value2 === undefined && value1 !== undefined) {
        return this.VALUE_DELETED;
      }

      return this.VALUE_UPDATED;
    },
    isFunction: function(x) {
      return Object.prototype.toString.call(x) === "[object Function]";
    },
    isArray: function(x) {
      return Object.prototype.toString.call(x) === "[object Array]";
    },
    isDate: function(x) {
      return Object.prototype.toString.call(x) === "[object Date]";
    },
    isObject: function(x) {
      return Object.prototype.toString.call(x) === "[object Object]";
    },
    isValue: function(x) {
      return !this.isObject(x) && !this.isArray(x);
    }
  };
}();

export const executeAndRetry = function(attemptCallback, successCallback, numberOfRetries, retryInterval) {
  function rejectDelay(reason) {
    return new Promise(function(resolve, reject) {
      setTimeout(reject.bind(null, reason), retryInterval);
    });
  }

  function test(val) {
    if (!val) {
      throw "Not finished";
    }

    return val;
  }

  function errorHandler(err) {
    Logger.error(err);
  }

  const max = numberOfRetries;
  let p = Promise.reject();
  for (let i = 0; i < max; i++) {
    p = p.catch(attemptCallback).then(test).catch(rejectDelay);
  }

  p.then(successCallback).catch(errorHandler);
};



// For tests who need to do things like modify the inactivity timer
window.UIUtilsForTests = module.exports;
